export const realData =
{
    "ClientImages": [
        {
            "ClientImageName": "Air Asia_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "Aryaduta_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "ASTON_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "CGV Cinemas_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "Chung Gi Wa Korean BBQ_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "Fairmont Hotels and Resorts_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "Ferrari_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "GoFood_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "GoPay_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "Haidilao Hot Pot_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "Hotel Santika_Client Tissue Basah Wet Wipes Indonesia.jpg"
        },
        {
            "ClientImageName": "JS Luwansa Hotel_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "JW Marriott_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "KFC_Client Tissue Basah Wet Wipes Indonesia.jpg"
        },
        {
            "ClientImageName": "Mercure Hotels Bandung_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "Mowilex Paints_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "Novotel Hotels Resorts_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "PT FREEPORT_Client Tissue Basah Wet Wipes Indonesia.jpg"
        },
        {
            "ClientImageName": "Pullman Hotels and Resorts_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "Shaburi Japanese_Client Tissue Basah Wet Wipes Indonesia.jpg"
        },
        {
            "ClientImageName": "Shangri-La Hotels and Resorts_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "ST Regis Hotel_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "Sun City Luxury Clun_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "Sushi Tei_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "The Alana Hotel_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "The Duck King Group_Client Tissue Basah Wet Wipes Indonesia.png"
        },
        {
            "ClientImageName": "The Westin_Client Tissue Basah Wet Wipes Indonesia.jpg"
        }
    ],
    "Services": [
        {
            "service_desc": "Swipes (Sanitizing Wipes) merupakan solusi terbaik untuk meningkatkan image perusahaan anda, karena menunjukan bahwa anda mempedulikan kesehatan customer, sehingga memberikan kesan positive untuk business anda.\n",
            "service_title": "Swipes as One Stop Hygiene Solution",
            "service_item_name": "Boost Brand Image",
            "service_item_desc": "Memberikan tissue basah sebagai bonus yang berguna akan memberikan kesan yang baik dan menunjang image anda di mata customer",
            "service_item_icon_name": "bx-comment-add",
            "en_service_desc": "By choosing Swipes (Sanitizing Wipes) you show that you care about your customer's health in giving a positive image for your business",
            "en_service_item_desc": "Providing Swipes to your customers will boost your brand image drastically"
        },
        {
            "service_desc": "Swipes (Sanitizing Wipes) merupakan solusi terbaik untuk meningkatkan image perusahaan anda, karena menunjukan bahwa anda mempedulikan kesehatan customer, sehingga memberikan kesan positive untuk business anda.\n",
            "service_title": "Swipes as One Stop Hygiene Solution",
            "service_item_name": "Enhance Customer's Experience",
            "service_item_desc": "Menggunakan tissue basah memberikan rasa segar dan meningkatkan kenyamanan, sehingga meningkatkan customer experience.",
            "service_item_icon_name": "bx-donate-blood",
            "en_service_desc": "By choosing Swipes (Sanitizing Wipes) you show that you care about your customer's health in giving a positive image for your business",
            "en_service_item_desc": "Swipes provides fresh vibes and comfort that definitely will enhance customer's experience"
        },
        {
            "service_desc": "Swipes (Sanitizing Wipes) merupakan solusi terbaik untuk meningkatkan image perusahaan anda, karena menunjukan bahwa anda mempedulikan kesehatan customer, sehingga memberikan kesan positive untuk business anda.\n",
            "service_title": "Swipes as One Stop Hygiene Solution",
            "service_item_name": "Extra Protection",
            "service_item_desc": "Tissue basah antibacterial kami memberikan perlindungan praktis dan efektif baik kepada anda maupun setiap customer anda",
            "service_item_icon_name": "bx-check-shield",
            "en_service_desc": "By choosing Swipes (Sanitizing Wipes) you show that you care about your customer's health in giving a positive image for your business",
            "en_service_item_desc": "Swipes provide comprehensive and effective protection to your customers"
        }
    ],
    "Values": [
        {
            "name": "Petite Size",
            "description": "Cocok untuk segala jenis acara dan kebutuhan",
            "value_pict": "petite",
            "en_description": "Suitable for any kind of events and needs"
        },
        {
            "name": "Prime Size",
            "description": "Cocok untuk disandingkan dengan peralatan makanan",
            "value_pict": "prime",
            "en_description": "Nice complimentary to cutleries"
        },
        {
            "name": "Premium Size",
            "description": "Ukuran besar memberikan kesan mewah dan exclusive.",
            "value_pict": "premium",
            "en_description": "Provide more exclusive and premium experience"
        },
        {
            "name": "Custom Sticker",
            "description": "Dapatkan tissue basah custom logo dengan pengerjaan cepat.",
            "value_pict": "semicustom",
            "en_description": "Tailor-made your packaging in short lead time process."
        },
        {
            "name": "Custom Printing- Joint project",
            "description": "Cetak logo anda pada packaging tissue basah sebagai marketing tools yang berguna",
            "value_pict": "customjointproject",
            "en_description": "Print you logo for an impressive and useful marketing tools"
        },
        {
            "name": "Custom Printing - Exclusive project",
            "description": "Tingkatkan brand image dengan tissue basah branding anda.",
            "value_pict": "customexclusiveproject",
            "en_description": "Customize the logo and size of your wet tissues as per your desire"
        }
    ],
    "Catalogues": [
        {
            "name": "[ALL]-Logo Alana  - Copy.jpg",
            "category": "custom tissue"
        },
        {
            "name": "[ALL]-23.jpg",
            "category": "petite tissue"
        },
        {
            "name": "[ALL]-42.jpg",
            "category": "petite tissue"
        },
        {
            "name": "[ALL]-Logo Sticker-2 (edited) - Copy.jpg",
            "category": "premium tissue"
        },
        {
            "name": "[ALL]-premium.jpg",
            "category": "custom tissue"
        },
        {
            "name": "[ALL]-Sushi tei.jpg",
            "category": "custom tissue"
        },
        {
            "name": "[CUSTOM]-Logo - Boga-2.jpg",
            "category": "custom tissue"
        },
        {
            "name": "[CUSTOM]-Logo Alana .jpg",
            "category": "custom tissue"
        },
        {
            "name": "[CUSTOM]-Logo KFC (edited).jpg",
            "category": "custom tissue"
        },
        {
            "name": "[CUSTOM]-Logo Sticker-2 (edited).jpg",
            "category": "custom tissue"
        },
        {
            "name": "[CUSTOM]-LogoChung Gi Wa2.jpg",
            "category": "custom tissue"
        },
        {
            "name": "[CUSTOM]-zcustom mix.jpg",
            "category": "custom tissue"
        },
        {
            "name": "[PETITE]-11.jpg",
            "category": "petite tissue"
        },
        {
            "name": "[PETITE]-23 - Copy.jpg",
            "category": "petite tissue"
        },
        {
            "name": "[PETITE]-42 - Copy.jpg",
            "category": "petite tissue"
        },
        {
            "name": "[PETITE]-WEB 1.jpg",
            "category": "petite tissue"
        },
        {
            "name": "[PETITE]-WEB 2 (1).jpg",
            "category": "petite tissue"
        },
        {
            "name": "[PETITE]-WEB 2.jpg",
            "category": "petite tissue"
        },
        {
            "name": "[PETITE]-WEB 5.jpg",
            "category": "petite tissue"
        },
        {
            "name": "[PETITE]-WEB 6.jpg",
            "category": "petite tissue"
        },
        {
            "name": "[PETITE]-Web-1.jpg",
            "category": "petite tissue"
        },
        {
            "name": "[PREMIUM]-1 WebsiteAplikasi Tangan.jpg",
            "category": "premium tissue"
        },
        {
            "name": "[PREMIUM]-5.jpg",
            "category": "premium tissue"
        },
        {
            "name": "[PREMIUM]-8.jpg",
            "category": "premium tissue"
        }
    ],
    "FAQ": [
        {
            "question": "Berapa Lama Tissue Dapat Disimpan?",
            "answer": "Kami menggunakan bahan material yang kedap air dan udara, sehingga tissue dapat disimpan sampai dengan 1 tahun dan tidak cepat kering.\r",
            "en_question": "How long can our tissues last in storage?",
            "en_answer": "We use air-tight and waterproof materials, hence our tissues can last up to 1 year and they don't get dried easily\r"
        },
        {
            "question": "Swipes digunakan di mana saja?\n",
            "answer": "Swipes cocok untuk kebutuhan tisu basah/ tissue basah restaurant, hotel, café, bar, catering, travel, bus, tour, pesawat, kereta api, bioskop, event, pameran, kantor, souvenir, stationery, promosi, dan masih banyak lainnya.\r",
            "en_question": "Where to use Swipes?",
            "en_answer": "Swipes can be used as wet tissues for restaurant, hotel, café, bar, catering, travel, bus, tour, aeroplane, train, theathers, event, exhibition, office, souvenir, stationery, promo event, dan many more"
        },
        {
            "question": "Swipes banyak ditemukan di kategori apa?\r",
            "answer": "Swipes juga sering disebut sebagai tissue basah atau tisu basah satuan, individual, petite, sachet, saset, mini, antiseptic, antibacterial, antivirus, antiseptic, antibakteri, sanitizer, desinfektan, food grade, polos.\r",
            "en_question": "In which categories can I find Swipes?",
            "en_answer": "Swipes can be categorized as wet tissues, single wet tissue, individual, petite, sachet, mini, antiseptic, antibacterial, antivirus, antiseptic,antibacterial, sanitizer,disinfectant,food grade,plain."
        }
    ],
    "Pricing": [
        {
            "name": "Petite",
            "price": "500",
            "packaging": "6x10",
            "size": "12x20",
            "motif": "Pilihan 10 motif",
            "desc": "Special discount available",
            "isJoinProject": "FALSE",
            "en_motif": "10 Choices of motifs",
            "link": "https://www.tokopedia.com/wetwipes/tissue-basah-sachet-petite-50pcs"
        },
        {
            "name": "Premium",
            "price": "600",
            "packaging": "6x13,5",
            "size": "15x20",
            "motif": "Pilihan 4 motif",
            "desc": "Special discount available",
            "isJoinProject": "FALSE",
            "en_motif": "4 Choices of motifs",
            "link": "https://www.tokopedia.com/wetwipes/tissue-basah-sachet-besar-50pcs"
        },
        {
            "name": "Custom Sticker",
            "price": "600",
            "packaging": "6x10",
            "size": "12x20",
            "motif": "Packaging Putih",
            "desc": "Sticker 3x3 cm / 2,5x4 cm",
            "isJoinProject": "FALSE",
            "en_motif": "Plain white packaging",
            "link": "https://www.tokopedia.com/wetwipes/tissue-basah-custom-sticker"
        },
        {
            "name": "Custom",
            "price": "780",
            "packaging": "6x13,5",
            "size": "15x20",
            "motif": "Full Color",
            "desc": "Minimum order 8000 pieces",
            "isJoinProject": "TRUE",
            "en_motif": "Full Color"
        }
    ],
    "AboutUs": [
        {
            "paragraph": "PT. Wet Wipes Indonesia merupakan produsen yang telah dipercaya oleh berbagai perusahaan dan ratusan customer sebagai penyedia tissue basah antibacterial dengan kemasan individual.\n",
            "advantage": "Sebagai Tissue Basah Antiseptik\n",
            "advantage_desc": "Usapkan Swipes pada permukaan tangan untuk membersihkan dan membasmi mikroorganisme berbahaya.\n",
            "advantage_icon": "bxs-virus-block",
            "en_paragraph": "PT. Wet Wipes Indonesia is a wet tissue manufacturer that has been trusted by many corporate clients in wide range of industries",
            "en_advantage": "Antiseptic wet tissues",
            "en_advantage_desc": "Use Swipes to your hand to clean and exterminate dangerous micro organism"
        },
        {
            "paragraph": "Produk kami merupakan solusi yang tepat sebagai perlindungan kesehatan karena menggunakan formulasi FOOD GRADE ANTIBACTERIAL yang telah teruji secara klinis 99,9% efektif membasmi kuman, virus dan bakteri.\n",
            "advantage": "Sebagai Tissue Basah Desinfektan\r\n",
            "advantage_desc": "Usapkan Swipes pada permukaan benda atau layar gadget anda agar bebas dari segala kuman yang menempel.",
            "advantage_icon": "bx-mobile",
            "en_paragraph": "Our Product use FOOD GRADE ANTIBACTERIAL that has been lab-tested and proven 99,9% killing bacterias, virus, and germs effectively.",
            "en_advantage": "Disinfectant wet tissues",
            "en_advantage_desc": "Use Swipes to any surface or gadget screen so that you're free of germs."
        },
        {
            "paragraph": "Swipes (Sanitizing Wipes) merupakan brand PT Wet Wipes Indonesia dengan keunggulan:",
            "advantage": "Formulasi Tissue Basah Food Grade\n",
            "advantage_desc": "Swipes menggunakan bahan bahan food grade baik dari material, cairan, maupun packagingnya sehingga aman untuk bersentuhan dengan makanan.\n",
            "advantage_icon": "bx-restaurant",
            "en_paragraph": "Swipes (Sanitizing Wipes) is the corporate brand of PT Wet Wipes Indonesia with advantages as follows:",
            "en_advantage": "Food grade formulation wet tissues",
            "en_advantage_desc": "Swipes use food grade materials so that it is safe to come into contact with food."
        },
        {
            "paragraph": "Swipes (Sanitizing Wipes) merupakan brand PT Wet Wipes Indonesia dengan keunggulan:",
            "advantage": "Produk telah teruji klinis",
            "advantage_desc": "Hasil produksi kami telah melewati serangkaian proses pengujian di laboratorium terakreditasi seperti SIG Lab, Biofarma, dan Sucofindo.",
            "advantage_icon": "bxs-flask",
            "en_paragraph": "Swipes (Sanitizing Wipes) is the corporate brand of PT Wet Wipes Indonesia with advantages as follows:",
            "en_advantage": "Clinically proven products",
            "en_advantage_desc": "Our products has passed several tests from credible laboratories such as SIG Lab, Biofarma and Sucofindo"
        }
    ],
    "WhyUs": [
        {
            "head_wording": "Mengapa memilih PT Wet Wipes ?",
            "wording": "Kami menjamin kepuasan customer dengan memberikan tissue basah berkualitas dan layanan yang cepat\n",
            "en_head_wording": "Why choose PT Wet Wipes?",
            "en_wording": "We ensure your satisfaction by our high quality products and good customer service"
        },
        {
            "head_wording": "Memiliki Sertifikat Produksi Kemenkes",
            "wording": "Perusahaan kami telah diaudit, diakui dan mendapat izin dari Kementerian Kesehatan Indonesia untuk produk tissue basah, antiseptik dan desinfektan\n",
            "icon_name": "bx-certification",
            "en_head_wording": "Certified by The Ministry of Health of Indonesia",
            "en_wording": "Our Company has been audited, acknowledged and certified by The Ministry of Health of Indonesia"
        },
        {
            "head_wording": "Memiliki Izin Edar Kemenkes",
            "wording": "Produk kami telah mendapatkan izin edar bertaraf Nasional dari Kementerian Kesehatan Indonesia sehingga terjamin mutunya.",
            "icon_name": "bx-paper-plane",
            "en_head_wording": "Trading licensed issued by The Ministry of Health of Indonesia",
            "en_wording": "Our products have obtained trading license issued by The Ministry of Health of Indonesia thus guaranteed it's quality"
        },
        {
            "head_wording": "Ahlinya Tissue Basah Custom",
            "wording": "Cetak tissue basah custom logo  dengan minimum order rendah dan pengerjaan tercepat.",
            "icon_name": "bx-trophy",
            "en_head_wording": "Expert in Customize Wet Tissue",
            "en_wording": "Printed tailor made wet tissues with minimum order and short processing time"
        }
    ]
}