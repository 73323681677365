import logo from './logo.svg';
import './App.css';
import Baker from "./components/baker"
import Flexor from "./components/flexor"
import IsotopeExample from "./components/isotopeExample"
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import React, {Fragment, useState, useEffect } from 'react';


function App() {

  useEffect(() => {
    // Update the document title using the browser API

    
   
  },[]);





  return (

    <Fragment>
      <Router historyy={createBrowserHistory}>
        <Switch>
          <Route 
            exact path ="/en"
            render = {props => <IsotopeExample lang={'en'} {...props}/>}
          />
          <Route 
            exact path ="/id"
            render = {props => <IsotopeExample lang={'id'} {...props}/>}
          />
          <Route
          exact path="/"
          render = {props => <IsotopeExample lang={'id'}{...props}/>}

          />
          
        </Switch>
      </Router>
    </Fragment>



    // <div className="App">
    //   <header className="App-header">
    //     {/* <img src={tes} className="App-logo" alt="logo" /> */}
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>

      
       
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
