import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Icon, InlineIcon } from '@iconify/react';
import {realData} from "./realData"
import bxsQuoteAltLeft from '@iconify-icons/bx/bxs-quote-alt-left';

import bxsQuoteAltRight from '@iconify-icons/bx/bxs-quote-alt-right';

import listReactFiles from 'list-react-files'
import axios from "axios"



export const aFportofolios = () => {
  return (
    <div>
    <div>
      <div className="col-lg-4 col-md-6 portfolio-item filter-app">
        <img src="../templates/Flexor/assets/img/portfolio/portfolio-1.jpg" className="img-fluid" alt />
        <div className="portfolio-info">
          <h4>App 1</h4>
          <p>App</p>
          <a href="../templates/Flexor/assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 1"><i className="bx bx-plus" /></a>
          <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 portfolio-item filter-web">
        <img src="../templates/Flexor/assets/img/portfolio/portfolio-2.jpg" className="img-fluid" alt />
        <div className="portfolio-info">
          <h4>Web 3</h4>
          <p>Web</p>
          <a href="../templates/Flexor/assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 3"><i className="bx bx-plus" /></a>
          <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 portfolio-item filter-app">
        <img src="../templates/Flexor/assets/img/portfolio/portfolio-3.jpg" className="img-fluid" alt />
        <div className="portfolio-info">
          <h4>App 2</h4>
          <p>App</p>
          <a href="../templates/Flexor/assets/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 2"><i className="bx bx-plus" /></a>
          <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 portfolio-item filter-card">
        <img src="../templates/Flexor/assets/img/portfolio/portfolio-4.jpg" className="img-fluid" alt />
        <div className="portfolio-info">
          <h4>Card 2</h4>
          <p>Card</p>
          <a href="../templates/Flexor/assets/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Card 2"><i className="bx bx-plus" /></a>
          <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 portfolio-item filter-web">
        <img src="../templates/Flexor/assets/img/portfolio/portfolio-5.jpg" className="img-fluid" alt />
        <div className="portfolio-info">
          <h4>Web 2</h4>
          <p>Web</p>
          <a href="../templates/Flexor/assets/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 2"><i className="bx bx-plus" /></a>
          <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 portfolio-item filter-app">
        <img src="../templates/Flexor/assets/img/portfolio/portfolio-6.jpg" className="img-fluid" alt />
        <div className="portfolio-info">
          <h4>App 3</h4>
          <p>App</p>
          <a href="../templates/Flexor/assets/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 3"><i className="bx bx-plus" /></a>
          <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 portfolio-item filter-card">
        <img src="../templates/Flexor/assets/img/portfolio/portfolio-7.jpg" className="img-fluid" alt />
        <div className="portfolio-info">
          <h4>Card 1</h4>
          <p>Card</p>
          <a href="../templates/Flexor/assets/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Card 1"><i className="bx bx-plus" /></a>
          <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 portfolio-item filter-card">
        <img src="../templates/Flexor/assets/img/portfolio/portfolio-8.jpg" className="img-fluid" alt />
        <div className="portfolio-info">
          <h4>Card 3</h4>
          <p>Card</p>
          <a href="../templates/Flexor/assets/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Card 3"><i className="bx bx-plus" /></a>
          <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 portfolio-item filter-web">
        <img src="../templates/Flexor/assets/img/portfolio/portfolio-9.jpg" className="img-fluid" alt />
        <div className="portfolio-info">
          <h4>Web 3</h4>
          <p>Web</p>
          <a href="../templates/Flexor/assets/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 3"><i className="bx bx-plus" /></a>
          <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
        </div>
      </div>
    </div>
    
        </div>
  )
}


export const fPortfolio = () => {
    let allPorfolios = []

    for (let i=1;i<=7;i++){

        let category = ""
        let titleName = ""
        let className = ""

        
        let currentI = i%3
        if (currentI==0){
            category = "filter-app"
            titleName = `App ${currentI+1}`

        }else if (currentI==1){
            category = "filter-card"
            titleName = `Card ${currentI+1}`
        }else{
            category = "filter-web"
            titleName = `Web ${currentI+1}`
        }
        
        let srcURL = `../templates/Flexor/assets/img/real/portfolios/portfolio-${i}.jpg`
        // let srcURL = `url(../templates/Flexor/assets/img/real/values/value--${i}.jpg)`
        className = "col-lg-4 col-md-6 portfolio-item "+category
       
        allPorfolios.push(

        <div className = {className}>
           <img src={srcURL} className="img-fluid" alt = {titleName} />
            {/* <div className={"portfolio-info"}>
              <h4>{titleName}</h4>
              <p>{titleName}</p>
              <a href={srcURL} data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title={titleName}><i className="bx bx-plus" /></a>
              <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
            </div> */}
          </div>
        )

    }
    return allPorfolios


}

export const fPortosArray = () => {

  let allPorfolios = []

    for (let i=1;i<=7;i++){

      let category = ""
        let titleName = ""
        let className = ""

        
        let currentI = i%3
        if (currentI==0){
            category = "app"
            titleName = `App ${Math.floor(i)+1}`

        }else if (currentI==1){
            category = "card"
            titleName = `Card ${Math.floor(i)+1}`
        }else{
            category = "web"
            titleName = `Web ${Math.floor(i)+1}`
        }
        let srcURL = `../templates/Flexor/assets/img/real/portfolios/portfolio-${i}.jpg`
        // let srcURL = `url(../templates/Flexor/assets/img/real/values/value--${i}.jpg)`
        className = "col-lg-4 col-md-6 portfolio-item "+category
        
        allPorfolios.push({
          className: className,
          srcURL: srcURL,
          category: category,
          titleName: titleName,
        })

    }

  return allPorfolios

}

export const fCatalogues = (category) => {
  let allCatalogues = []

  realData.Catalogues.map((cat)=>{
   let tempArray = cat.name.split('-')

   if(tempArray[0] === category){
    allCatalogues.push({
      className :  "col-lg-4 col-md-6 portfolio-item "+tempArray[0],
      category: tempArray[0],
      srcURL: `../templates/Flexor/assets/img/real/catalogues/${cat.name}`,
      titleName: cat.category,
      
    })

   }
  
  })

  return allCatalogues
}




export const fValues = (lang) => {

  

    let allValues = []
    realData.Values.map((val,i)=> {
      let dummyIndex = (i+1)%7
      let srcURL = `url(../templates/Flexor/assets/img/real/values/${val.value_pict}.jpg)`
      let delay = (dummyIndex-1)*100
      let valueName = val.name
      let description = lang === 'id' ? (val.description) : (val.en_description)
     
      allValues.push(

        <div className="col-md-6 d-flex align-items-stretch" data-aos="fade-up"  data-aos-delay={delay}>
        <div className="card" style={{backgroundImage: srcURL}}>
          <div className="card-body">
            <h5 className="card-title"><a href="#">{valueName}</a></h5>
            <p className="card-text">{description}</p>
           
            {/* <div className="read-more"><a href="#"><i className="bi bi-arrow-right" /> Read More</a></div> */}
          </div>
        </div>
      </div>

  
    )

    })
    // for(let i=1;i<=7;i++){
    //     let srcURL = `url(../templates/Flexor/assets/img/real/values/values--${i}.jpg)`
    //     // let srcURL = `url(../templates/Flexor/assets/img/apple-touch-icon.png)`
    //     let delay = (i-1)*100
    //     let valueName = `Value-${i}`
    //     allValues.push(

    //         <div className="col-md-6 d-flex align-items-stretch" data-aos="fade-up"  data-aos-delay={delay}>
    //         <div className="card" style={{backgroundImage: srcURL}}>
    //           <div className="card-body">
    //             <h5 className="card-title"><a href="#">{valueName}</a></h5>
    //             <p className="card-text">Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>
    //             <p className="card-text">Lorem ipsum dolor sit amet, consecte et dolore magna aliqua.</p>
    //             {/* <div className="read-more"><a href="#"><i className="bi bi-arrow-right" /> Read More</a></div> */}
    //           </div>
    //         </div>
    //       </div>

      
    //     )

    // }

    return allValues

}



export const fClients = () =>{
   

   let allClients = []

   realData.ClientImages.map((img) => {
    let imgName = img.ClientImageName
    let imgAlt = imgName.split('.')[0]
   
    allClients.push(
      <SwiperSlide>
           <div className="swiper-slide">
          <img src={`../templates/Flexor/assets/img/real/clients/logoclient/${imgName}`} className="img-fluid" alt={imgAlt} />
            </div>
      </SwiperSlide>   
  )
   })

    // for (let i=1; i<=10;i++){
    //     allClients.push(
    //         <SwiperSlide>
    //              <div className="swiper-slide">
    //             <img src={`../templates/Flexor/assets/img/real/clients/client-${i}.jpg`} className="img-fluid" alt />
    //               </div>
    //         </SwiperSlide>   
    //     )
    // }
    return allClients
}

export const fTestimonials = () => {

    let allTesti = []
    let numTesti = allTestimonies.length
    

    for (let i = 0; i<numTesti;i++){
        let person = allTestimonies[i]
        let name = person.name
        let role = person.position
        let srcURL = `../templates/Flexor/assets/img/real/testimonials/${person.id}-${name}.jpg`
        allTesti.push(
            <SwiperSlide>
          <div className="swiper-slide">
            <div class="testimonial-wrap">
            
              <div className="testimonial-item">
                <img src={srcURL} className="testimonial-img" alt={name} />
                <h3>{name}</h3>
                <h4>{role}</h4>
                <p>
               
                 <Icon icon={bxsQuoteAltLeft} style={{
                    color: "rgba(255, 255, 255, 0.4)",
                    fontSize: 26,
                    display: "inline-block",
                    left: -5,
                    position: "relative",

                  }}/>
                {person.words}
                  <Icon icon={bxsQuoteAltRight}
                  style={{
                    color: "rgba(255, 255, 255, 0.4)",
                    fontSize: 26,
                    display: "inline-block",
                    right: -5,
                   
                    position: "relative",

                  }}/>
                </p>
              </div>
             </div> 
            </div>
            </SwiperSlide>
        )
    }

    return allTesti

}

export const fServices = (lang) => {
  let allServices = []

  realData.Services.map((service)=>{
    allServices.push(
      <div className="col-lg-4 col-md-6" data-aos="fade-up">
            <div className="icon-box">
              <div className="icon"><i className={`bx ${service.service_item_icon_name}`} /></div>
              <h4 className="title"><a href="#">{service.service_item_name}</a></h4>
              <p className="description">{lang === 'id' ? (service.service_item_desc):(service.en_service_item_desc)}</p>
            </div>
      </div>
    )
  })

  return allServices

}

export const fServicesTitle = () => {
  return realData.Services[0].service_title
}

export const fServicesDesc = (lang) => {
  return lang === 'id' ? (realData.Services[0].service_desc) :(realData.Services[0].en_service_desc)
}

export const fAboutUs = (lang) => {
  let allParagraphs = []

  realData.AboutUs.map((par,i)=>{
    if (i<realData.AboutUs.length-2){

      // let paragraph = lang === 'id' ? (par.paragraph):(par.en_paragraph)

      
      allParagraphs.push(
        

        <p data-aos="fade-up">{lang === 'id' ? (par.paragraph):(par.en_paragraph)}</p>
        

      )
    }
  })
  return allParagraphs
}

export const fAdvantageTitle =(lang) => {
  return lang === 'id' ? (realData.AboutUs[realData.AboutUs.length-1].paragraph) : (realData.AboutUs[realData.AboutUs.length-1].en_paragraph)
}

export const fAdvantages = (lang) => {
  let allAdvantages = []
  realData.AboutUs.map((item,i)=>{
    allAdvantages.push(
      <div className="icon-box" data-aos="fade-up" data-aos-delay={i*100}>
          <div className="icon"><i className={`bx ${item.advantage_icon}`} /></div>
          <h4 className="title"><a href="#">{lang=== 'id' ? (item.advantage):(item.en_advantage)}</a></h4>
          <p className="description">{lang === 'id'? (item.advantage_desc) : (item.en_advantage_desc)}</p>
      </div>
    )
  })
  return allAdvantages
}


export const fPrices = (lang) => {
  let allPrices = []

  realData.Pricing.map((price)=>{
    if (price.isJoinProject === "FALSE"){

      allPrices.push(
        <div className="col-lg-3 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay={200}>
            <div className="box featured">
              <h3>{price.name}</h3>
              <h4><sup>Rp</sup>{price.price}<span> / piece</span></h4>
              <ul>
                <li>Packaging {price.packaging} cm</li>
                <li>Size {price.size} cm</li>
                <li>{lang === 'id' ? (price.motif) :(price.en_motif)}</li>
                <li>{price.desc}</li>
      
              </ul>
              <div className="btn-wrap">
                <a href={price.link} target="_blank" className="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>
      )

    }else{

      allPrices.push(
        <div className="col-lg-3 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay={300}>
            <div className="box">
              <span className="advanced">Joint Project</span>
              <h3>{price.name}</h3>
              <h4><sup>Rp</sup>{price.price}<span> / piece</span></h4>
              <ul>
                <li>Packaging {price.packaging} cm</li>
                <li>Size {price.size} cm</li>
                <li>{price.motif}</li>
                <li>{price.desc}</li>
      
              </ul>
              <div className="btn-wrap">
                <a href="#contact" className="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>
      )

    }
  })
  return allPrices
}

export const fWhyUsTitle = (lang) => {
  let data = realData.WhyUs[0]
  return (
    <div className="col-xl-4 col-lg-5" data-aos="fade-up">
            <div className="content">
              <h3>{lang === 'id' ? (data.head_wording) :(data.en_head_wording)}</h3>
              <p>
              {lang === 'id' ? (data.wording) : (data.en_wording)}
              </p>
              <div className="text-center">
                <a href="#" className="more-btn">Learn More <i className="bx bx-chevron-right" /></a>
              </div>
            </div>
          </div>
  )

}

export const fWhyUsDesc = (lang) => {
  let allWhyUsDescs = []

  realData.WhyUs.map((data,i)=>{
    if(i>=1){
      allWhyUsDescs.push(

        <div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={i*100}>
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className={`bx ${data.icon_name}`} />
                    <h4>{lang === 'id' ? (data.head_wording) :(data.en_head_wording)}</h4>
                    <p>{lang === 'id' ? (data.wording) : (data.en_wording)}</p>
                  </div>
        </div>

        

      )
    }
  })
  return allWhyUsDescs

}

export const fFAQ = (lang) => {
  let allFAQS = []

  realData.FAQ.map((ques,i)=>{
    allFAQS.push(
      <li data-aos="fade-up" data-aos-delay={i*100}>
      <i className="bx bx-help-circle icon-help" />
       <a href="#" data-bs-toggle="collapse" data-bs-target={`#faq-list-${i+1}`} className="collapsed">
        {lang === 'id' ? (ques.question) : (ques.en_question)}
        <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" />
        </a>
      <div id={`faq-list-${i+1}`} className="collapse show" data-bs-parent=".faq-list">
        <p>
         {lang === 'id' ? (ques.answer) :(ques.en_answer)}
        </p>
      </div>
    </li>
    )
  })
  return allFAQS
}



const allTestimonies = [
  {
    id: 1,
    name: "Leo",
    position: "Purchasing PT Jaya Bersama Indo",
    company: "Duck King Group",
    words: "Good quality, good product and good service",
  },
  {
    id: 2,
    name: "Johan",
    position: "Purchasing Manager",
    company: "Boga Group",
    words: "Good service and product",
  },
  {
    id: 3,
    name: "Novi",
    position: "Purchasing",
    company: "PT. Panca Abadi Nan Jaya (SUSHI TEI)",
    words: "Proses pengiriman dari buka PO cepat sampai ke tempat.Komunikasi baik. Fast Response",
  },
  {
    id: 4,
    name: "Petrus Limantoyo",
    position: "Cluster Director of Purchasing",
    company: "The Westin Surabaya",
    words: "Service yang diberikan oleh Bu Yostina sangat baik dan respon yang diberikan juga cepat, sehingga memudahkan untuk bertransaksi",
  },
  {
    id: 5,
    name: "Hendy Wijaya",
    position: "SPV Purchasing Union",
    company: "UNION GROUP",
    words: "Good service, best price,best product",
  },
  {
    id: 6,
    name: "Melanie Tanidjaja",
    position: "Assistant Manager - Event Planning",
    company: "Hyatt Regency Bali",
    words: "Good product quality, great value for its price. Excellent service. Very recommended",
  },
]