import React, { useState, useEffect, useRef } from "react";
import Isotope from "isotope-layout";
import {useForm} from 'react-hook-form'
import IsoTopeGrid, { Card } from "react-isotope";
import GLightbox from "glightbox"
import axios from "axios"

import AOS from "aos"
import {fClients, fTestimonials, fValues,
   fPortfolio, fPortosArray,fServices,fServicesDesc,
   fServicesTitle,fAboutUs,fAdvantageTitle,fAdvantages,
    fPrices,fWhyUsDesc,fWhyUsTitle,fFAQ,fCatalogues} from "../dummyMedia/flexorDummyMedia"

import SwiperCore, { Navigation, Pagination, Controller, Autoplay,} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';


import{ init, sendForm } from 'emailjs-com';



init("user_URBzp6wZmLQDViPe3m6FG");

SwiperCore.use([Pagination,Autoplay])
export default function IsotopeExample(props){

  
  let lang = props.lang
  
  const { register, handleSubmit, watch, errors } = useForm();
 

  const message = watch('message') || "";
  const messageCharsLeft = 1500 - message.length;
  const [contactNumber, setContactNumber] = useState("000000");
  
  const generateContactNumber = () => {
    const numStr = "000000" + (Math.random() * 1000000 | 0);
    setContactNumber(numStr.substring(numStr.length - 6));
  }

  

  const onSubmit = (data) => {
    // console.log(data);
    generateContactNumber();
    sendForm('default_service', 'template_10t6gqm', '#contact-form')
      .then(function(response) {
        alert('Email sukses dikirim! Kami akan membalas secepatnya !')
        console.log('SUCCESS!', response.status, response.text);
        window.location.reload(false)
      }, function(error) {
        alert('Email gagal dikirim, silahkan coba lagi')
        console.log('FAILED...', error);
        window.location.reload(false)
      });
  }
  

  
    // init one ref to store the future isotope object
  const isotope = useRef()
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState('*')
  const [clientImages, setClientImages] = useState([])
  const [currentPortfolios, setCurrentPortfolios] = useState(fCatalogues('[ALL]'))

  const handleOnClickPortfolioCategory = (e) => {

    // console.log(e.target.accessKey)
    // let categories = ['app','web','card']
    // let filtered = []
    // let defaultFilter = fPortosArray()
    // if (categories.includes(e.target.accessKey)) {
    // filtered = defaultFilter.filter(porto => {
    //   return porto.category === e.target.accessKey
    // })
    
    // }else{
    //   filtered = defaultFilter
    // }
    // console.log(filtered)
    // setCurrentPortfolios(filtered)
    setCurrentPortfolios(fCatalogues(e.target.accessKey))

   
    
  }

  



  useEffect(() => {


    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
          return [...document.querySelectorAll(el)]
        } else {
          return document.querySelector(el)
        }
      }
    
      const on = (type, el, listener, all = false) => {
        let selectEl = select(el, all)
        if (selectEl) {
          if (all) {
            selectEl.forEach(e => e.addEventListener(type, listener))
          } else {
            selectEl.addEventListener(type, listener)
          }
        }
      }

    // let portfolioContainer = select('.portfolio-container');

   
    // isotope.current = new Isotope(portfolioContainer, {
    //   itemSelector: '.portfolio-item',
    //   layoutMode: 'fitRows',
    // })

    AOS.init({
        duration: 1000,
        easing: 'ease-in-out',
        once: true,
        mirror: false
    })

   
    //useffect from main code

   
 

      /**
   * Easy on scroll event listener 
   */
  const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
  }

  /**
   * Navbar links active state on scroll
   */
   let navbarlinks = select('#navbar .scrollto', true)
   const navbarlinksActive = () => {
     let position = window.scrollY + 200
     navbarlinks.forEach(navbarlink => {
       if (!navbarlink.hash) return
       let section = select(navbarlink.hash)
       if (!section) return
       if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
         navbarlink.classList.add('active')
       } else {
         navbarlink.classList.remove('active')
       }
     })
   }
   window.addEventListener('load', navbarlinksActive)
   onscroll(document, navbarlinksActive)

   /**
   * Scrolls to an element with header offset
   */
  const scrollto = (el) => {
    let header = select('#header')
    let offset = header.offsetHeight

    if (!header.classList.contains('header-scrolled')) {
      offset -= 10
    }

    let elementPos = select(el).offsetTop
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    })
  }

  /**
   * Header fixed top on scroll
   */
  let selectHeader = select('#header')
  if (selectHeader) {
    let headerOffset = selectHeader.offsetTop
    let nextElement = selectHeader.nextElementSibling
    const headerFixed = () => {
      if ((headerOffset - window.scrollY) <= 0) {
        selectHeader.classList.add('fixed-top')
        nextElement.classList.add('scrolled-offset')
      } else {
        selectHeader.classList.remove('fixed-top')
        nextElement.classList.remove('scrolled-offset')
      }
    }
    window.addEventListener('load', headerFixed)
    onscroll(document, headerFixed)

    const headerScrolled = () => {
      if (window.scrollY > 100) {
        selectHeader.classList.add('header-scrolled')
      } else {
        selectHeader.classList.remove('header-scrolled')
      }
    }
    window.addEventListener('load', headerScrolled)
    onscroll(document, headerScrolled)


  }

  /**
   * Back to top button
   */
  let backtotop = select('.back-to-top')
  if (backtotop) {
      
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add('active')
      } else {
        backtotop.classList.remove('active')
      }
    }
    window.addEventListener('load', toggleBacktotop)
    onscroll(document, toggleBacktotop)
  }

  /**
   * Mobile nav toggle
   */
  on('click', '.mobile-nav-toggle', function(e) {
    select('#navbar').classList.toggle('navbar-mobile')
    this.classList.toggle('bi-list')
    this.classList.toggle('bi-x')
  })

  /**
   * Mobile nav dropdowns activate
   */
  on('click', '.navbar .dropdown > a', function(e) {
    if (select('#navbar').classList.contains('navbar-mobile')) {
      e.preventDefault()
      this.nextElementSibling.classList.toggle('dropdown-active')
    }
  }, true)

  /**
   * Scrool with ofset on links with a class name .scrollto
   */
  on('click', '.scrollto', function(e) {
    if (select(this.hash)) {
      e.preventDefault()

      let navbar = select('#navbar')
      if (navbar.classList.contains('navbar-mobile')) {
        navbar.classList.remove('navbar-mobile')
        let navbarToggle = select('.mobile-nav-toggle')
        navbarToggle.classList.toggle('bi-list')
        navbarToggle.classList.toggle('bi-x')
      }
      scrollto(this.hash)
    }
  }, true)

  /**
   * Scroll with ofset on page load with hash links in the url
   */
  window.addEventListener('load', () => {
    if (window.location.hash) {
      if (select(window.location.hash)) {
        scrollto(window.location.hash)
      }
    }
  });

  /**
   * Initiate glightbox 
   */
  const glightbox = GLightbox({
    selector: '.glightbox'
  });

  
    //enduseffect from main code

   

    // cleanup
    // return () => isotope.current.destroy()
  }, [])

  // handling filter key change
  useEffect(() => {

    // let portfolioContainer = select('.portfolio-container');

    // filterKey == '*'
    //   ? isotope.current.arrange({filter: `*`})
    //   : isotope.current.arrange({filter: `.${filterKey}`})


    
    
    // isotope.current.on('arrangeComplete', function() {
    //     AOS.refresh()
    // });

      
  }, [])



  const handleFilterKeyChange = key => () => setFilterKey(key)
  

  return (
    <div>
        <header id="header" className="fixed-top header-transparent">
    <div className="container d-flex align-items-center justify-content-between">
      <h1 className="logo">
        <a href="/">Wet Wipes</a>
        {/* <img src={`../templates/Flexor/assets/img/real/wet wipes new logo-01.png`}></img> */}
        
        </h1>
      {/* Uncomment below if you prefer to use an image logo */}
      {/* <a href="index.html" class="logo"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>*/}
      <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
          <li className="dropdown"><a href="#"><span>Language</span> <i className="bi bi-chevron-down" /></a>
            <ul>
              <li><a href="/en">English</a></li>
              {/* <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li> */}
              <li><a href="/">Indonesia</a></li>
              {/* <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li> */}
            </ul>
          </li>

          <li><a className="nav-link scrollto" href="#about">About</a></li>
          <li><a className="nav-link scrollto" href="#services">Services</a></li>
          <li><a className="nav-link scrollto " href="#portfolio">Catalogue</a></li>
          {/* <li><a className="nav-link scrollto" href="#team">Team</a></li> */}
          <li><a className="nav-link scrollto" href="#pricing">Pricing</a></li>
          <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle" />
      </nav>{/* .navbar */}
    </div>
  </header>

  <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
    <div className="container" data-aos="fade-in">
      <h1>Wet Wipes Indonesia</h1>
     
      {/* <h2>We are team of talented designers making websites with Bootstrap</h2> */}
      <div className="d-flex align-items-center">
        <i className="bx bxs-right-arrow-alt get-started-icon" />
        <a href="#about" className="btn-get-started scrollto">Get Started</a>
      </div>
    </div>
  </section>

     <main id="main">

         {/* ======= Why Us Section ======= */}
    <section id="why-us" className="why-us">
      <div className="container">
        <div className="row">

          {fWhyUsTitle(lang)}

         

          <div className="col-xl-8 col-lg-7 d-flex">
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <div className="row">

                {fWhyUsDesc(lang)}

                
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>{/* End Why Us Section */}


    {/* ======= About Section ======= */}
    <section id="about" className="about section-bg">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative" data-aos="fade-right">
            <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox mb-4" />
          </div>
          <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
            <h4 data-aos="fade-up">{lang === 'id'? ('Tentang Kami'):('About Us')}</h4>

            {fAboutUs(lang)}
           

            
            <h3 data-aos="fade-up">{fAdvantageTitle(lang)}</h3>

            {fAdvantages(lang)}
            
            
          </div>
        </div>
      </div>
    </section>{/* End About Section */}


    {/* ======= Clients Section ======= */}
    <section id="clients" className="clients">
      <div className="container" data-aos="fade-up">

      <div className="section-title">
          <h2 data-aos="fade-up">Our Portfolio</h2>
      </div>

        <Swiper

        speed={400}
        loop={true}
        autoplay = {{
          delay: 5000,
          disableOnInteraction: false,
        }}
        slidesPerView={'auto'}
        // pagination={{
          
        //   type:'bullets',
        //   clickable: true,
        // }}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 40
          },
          480: {
            slidesPerView: 3,
            spaceBetween: 60
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 80
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 120
          }
        }}

        >
        <div className="clients-slider swiper-container">
          <div className="swiper-wrapper align-items-center">

           
             {/* {clientImages} */}
             {fClients()}
   
           
            
          </div>
          <div className="swiper-pagination" />
        </div>
        
        </Swiper>

      </div>
    </section>{/* End Clients Section */}

    {/* ======= Services Section ======= */}
    <section id="services" className="services section-bg">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>{fServicesTitle()}</h2>
          <p>{fServicesDesc(lang)}</p>
        </div>

        <div className="row">

          {fServices(lang)}


        </div>
      </div>
    </section>{/* End Services Section */}


    {/* ======= Values Section ======= */}
    <section id="values" className="values">
      <div className="container">
        <div className="row">


          {fValues(lang)}

         

        </div>
      </div>
    </section>{/* End Values Section */}

    {/* ======= Testimonials Section ======= */}
    <section id="testimonials" className="testimonials section-bg">
      <div className="container position-relative" data-aos="fade-up">

      <div class="section-title" data-aos="fade-up">
          <h2>Testimonials</h2>
          <p>{lang === 'id' ? 
          (`Kepuasan customer adalah cerminan dari produk dan pelayanan kami.`):
          (`Customer's satisfaction is the reflection of our product and services`)
          
          
          }</p>
          {/* <p>Magnam dolores commodi suscipit eum quidem consectetur velit</p> */}
        </div>
          
      <Swiper
      speed={600}
      loop={true}
      autoplay={{
        delay:5000,
        disableOnInteraction: false,
      }}
      slidesPerView={'auto'}
      pagination={{
        
        type:'bullets',
        clickable: true,
      }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
  
        1200: {
          slidesPerView: 2,
          spaceBetween: 20
        }
      }}
      
      >  
        <div className="testimonials-slider swiper-container" data-aos="fade-up" data-aos-delay={100}>
         
          <div className="swiper-wrapper">
          

              <div>

                {fTestimonials()}

              </div>            
            
          </div>
          <div className="swiper-pagination" />
          
        </div>

        </Swiper>
     
      </div>
    </section>{/* End Testimonials Section */}


    {/* ======= Portfolio Section ======= */}
    <section id="portfolio" className="portfolio">
      <div className="container">

        <div className="section-title">
          <h2 data-aos="fade-up">Catalogue</h2>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay={100}>


          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
            <li accessKey='[ALL]' onClick={handleOnClickPortfolioCategory}>All</li>
                <li accessKey='[PETITE]' onClick={handleOnClickPortfolioCategory}>Petite</li>
                <li accessKey='[PREMIUM]' onClick={handleOnClickPortfolioCategory}>Premium</li>
                <li accessKey='[CUSTOM]' onClick={handleOnClickPortfolioCategory}>Custom</li>
            </ul>
          </div>

        </div>

        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay={200}>
          {currentPortfolios.map((port)=> {
  
            return (
                    <div className = {port.className}>
                    <img src={port.srcURL} className="img-fluid" alt={port.titleName} />
  
                    </div>
            )
          })}
          
        </div>


      </div>


    </section>
    
    {/* End Portfolio Section */}

     {/* ======= Pricing Section ======= */}
     <section id="pricing" className="pricing">
      <div className="container">
        <div className="section-title">
          <h2 data-aos="fade-up">Pricing</h2>
          {/* <p data-aos="fade-up">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
        </div>
        <div className="row">

          {fPrices(lang)}

          

        </div>
      </div>
    </section>{/* End Pricing Section */}

        {/* ======= F.A.Q Section ======= */}
        <section id="faq" className="faq section-bg">
      <div className="container">
        <div className="section-title">
          <h2 data-aos="fade-up">F.A.Q.</h2>
          {/* <p data-aos="fade-up">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
        </div>
        <div className="faq-list">
          <ul>

            {fFAQ(lang)}


            
          </ul>
        </div>
      </div>
    </section>{/* End F.A.Q Section */}

    {/* ======= Contact Section ======= */}
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title">
          <h2 data-aos="fade-up">Contact</h2>
          <p>
            {lang === 'id' ? 
            (`Klik tombol Whatsapp di pojok kanan bawah untuk pertanyaan lebih lanjut atau permintaan FREE sample.`):
            (`Click the Whatsapp button on bottom right corner for further questions/enquiries or FREE sample request`)
            }
          </p>
          {/* <p data-aos="fade-up">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up">
            <div className="info-box">
              <i className="bx bx-map" />
              <h3>Our Address</h3>
              <p>Scientia Business Park. Tower 2, 2nd Floor Block 0/2
Jl. Boulevard Gading Serpong, Tangerang 15810, Indonesia</p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay={100}>
            <div className="info-box">
              <i className="bx bx-envelope" />
              <h3>Email Us</h3>
              <p>admin@wetwipes.co.id<br />yostina@wetwipes.co.id</p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay={200}>
            <div className="info-box">
              <i className="bx bx-phone-call" />
              <h3>Call Us</h3>
              <p>+62 21 2188 5370<br />+62 812 1388 1868</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center" data-aos="fade-up" data-aos-delay={300}>
          <div className="col-xl-9 col-lg-12 mt-4">

            <form id="contact-form"  className="php-email-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-6 form-group">
                  <input ref={register} type="text" name="name" className="form-control" id="user_name" placeholder="Your Name"  required />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input ref={register} type="email" className="form-control" name="email" id="user_email" placeholder="Your Email" required />
                </div>
              </div>
              <div className="form-group mt-3">
                <input ref={register} type="text" className="form-control" name="subject" id="user_subject" placeholder="Subject" required />
              </div>
              <div className="form-group mt-3">
                <textarea ref={register} className="form-control" name="message" rows={5} placeholder="Message" required defaultValue={""} />
                <p>{lang === 'id'? (`Sisa Karakter:`):(`Characters left:`)} {messageCharsLeft}</p>
              </div>

              <input type='hidden' name='contact_number' value={contactNumber} />

              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message" />
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>

              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>


          </div>
        </div>
      </div>
    </section>{/* End Contact Section */}


    </main>{/* End #main */}

    <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 footer-contact">
            <h3>Wet Wipes</h3>
            <p>
              {/* A108 Adam Street <br />
              New York, NY 535022<br />
              United States <br /><br /> */}
              Scientia Business Park. Tower 2, 2nd Floor Block 0/2 Jl. Boulevard Gading Serpong, Tangerang 15810, Indonesia
              <br /><br />
              <strong>Phone:</strong> +62 812 1388 1868<br />
              <strong>Email:</strong> admin@wetwipes.co.id<br />
            </p>
          </div>
          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right" /> <a href="#hero">Home</a></li>
              <li><i className="bx bx-chevron-right" /> <a href={lang === 'id' ? ('/en'):('/')}>{lang === 'id' ? ('English'):('Bahasa Indonesia')}</a></li>

              <li><i className="bx bx-chevron-right" /> <a href="#about">About us</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#services">Services</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#portfolio">Catalogue</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#pricing">Pricing</a></li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Shop Our Products</h4>
            <ul>
              <li><i className="bx bx-chevron-right" /> <a href={`https://www.tokopedia.com/wetwipes?source=universe&st=product`} target="_blank">Tokopedia</a></li>
              <li><i className="bx bx-chevron-right" /> <a href={`https://shopee.co.id/wetwipes.id`} target="_blank">Shopee</a></li>
              <li><i className="bx bx-chevron-right" /> <a href={`https://www.bukalapak.com/u/yostinaanggraini?from=dropdown`} target="_blank">Bukalapak</a></li>
              <li><i className="bx bx-chevron-right" /> <a href={`https://www.lazada.co.id/wetwipesid/`} target="_blank">Lazada</a></li>
              <li><i className="bx bx-chevron-right" /> <a href={`https://www.instagram.com/wetwipes.id/`} target="_blank">Instagram</a></li>
              <li><i className="bx bx-chevron-right" /> <a href={"https://api.whatsapp.com/send/?phone=%2B6281213881868&text&app_absent=0"} target="_blank">Whatsapp</a></li>
            </ul>
          </div>

          {/* <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Join Our Newsletter</h4>
            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
            <form action method="post">
              <input type="email" name="email" /><input type="submit" defaultValue="Subscribe" />
            </form>
          </div> */}

        </div>
      </div>
    </div>
    <div className="container d-lg-flex py-4">
      <div className="me-lg-auto text-center text-lg-start">
        <div className="copyright">
          © Copyright <strong><span>Wet Wipes 2021</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
          {/* All the links in the footer should remain intact. */}
          {/* You can delete the links only if you purchased the pro version. */}
          {/* Licensing information: https://bootstrapmade.com/license/ */}
          {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/Wet Wipes-free-multipurpose-bootstrap-template/ */}
          {/* Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> */}
        </div>
      </div>
      <div className="social-links text-center text-lg-right pt-3 pt-lg-0">
       
        
        <a href={`https://shopee.co.id/wet.wipes.indonesia`} target="_blank"  className="instagram">

        <img src={`../shopee.png`} style={{
            maxWidth: 20,
            maxHeight:20,
        }} alt={`wet wipes shopee`}/>
            
        </a>
        <a href={`https://www.tokopedia.com/wetwipes`} target="_blank" className="google-plus"> <img src={`../tokopedia.png`} style={{
            maxWidth: 20,
            maxHeight:20,
        }} alt={`wet wipes tokopedia`}/></a>
        
        <a href={"https://api.whatsapp.com/send/?phone=%2B6281213881868&text&app_absent=0"} target="_blank" className="whatsapp"><i className="bx bxl-whatsapp" /></a>
      </div>
    </div>
  </footer>{/* End Footer */}

  <a href={"https://api.whatsapp.com/send/?phone=%2B6281213881868&text&app_absent=0"} target="_blank" className="back-to-top d-flex align-items-center justify-content-center">
     

      <i className="bx bxl-whatsapp" />
      
      </a>


    </div>
  )
}