import React, {Fragment, useState, useEffect,useRef } from 'react';
import GLightbox from "glightbox"
import Isotope from "react-isotope"
import AOS from "aos"
import { Icon, InlineIcon } from '@iconify/react';
import bxsQuoteAltLeft from '@iconify-icons/bx/bxs-quote-alt-left';

import bxsQuoteAltRight from '@iconify-icons/bx/bxs-quote-alt-right';
import {fClients, fTestimonials, fValues, fPortfolio} from "../dummyMedia/flexorDummyMedia"
import SwiperCore, { Navigation, Pagination, Controller, Autoplay,} from 'swiper';

// import tesImage from "../templates/RealAssets/real-value-1.jpg"
// import tesImage2 from "../templates/RealAssets/real-value-2.jpg"

// import "../templates/Flexor/assets/css/style.css"
// import "../templates/Flexor/assets/vendor/boxicons/css/boxicons.css"




import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Pagination,Autoplay])

export default function Flexor(props){

  
  const isotope = React.useRef()
  
  const [filterKey, setFilterKey] = React.useState('*')
  const handleOnClick = (e) => {
    console.log(e.target.accessKey)
    console.log('sssssss')
  }

  
  // useEffect(() => {
  //   isotope.current = new Isotope('.portfolio-container', {
  //     itemSelector: '.portfolio-item',
  //     layoutMode: 'fitRows',
  //   })
  //   // cleanup
  //   return () => isotope.current.destroy()
  // }, [])

  
  // useEffect(() => {
  //   filterKey === '*'
  //     ? isotope.current.arrange({filter: `*`})
  //     : isotope.current.arrange({filter: `.${filterKey}`})

  //      //   isotope.current.on('arrangeComplete', function() {
  //       //     AOS.refresh()
  //       //   });
  //       // }, true);
  // }, [filterKey])



  // const handleFilterKeyChange = key => () => setFilterKey(key)




  
 

  useEffect(() => {


    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      } else {
        return document.querySelector(el)
      }
    }

    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all)
      if (selectEl) {
        if (all) {
          selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
          selectEl.addEventListener(type, listener)
        }
      }
    }

    
  /**
   * Easy on scroll event listener 
   */
  const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
  }

  /**
   * Navbar links active state on scroll
   */
   let navbarlinks = select('#navbar .scrollto', true)
   const navbarlinksActive = () => {
     let position = window.scrollY + 200
     navbarlinks.forEach(navbarlink => {
       if (!navbarlink.hash) return
       let section = select(navbarlink.hash)
       if (!section) return
       if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
         navbarlink.classList.add('active')
       } else {
         navbarlink.classList.remove('active')
       }
     })
   }
   window.addEventListener('load', navbarlinksActive)
   onscroll(document, navbarlinksActive)

   /**
   * Scrolls to an element with header offset
   */
  const scrollto = (el) => {
    let header = select('#header')
    let offset = header.offsetHeight

    if (!header.classList.contains('header-scrolled')) {
      offset -= 10
    }

    let elementPos = select(el).offsetTop
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    })
  }

  /**
   * Header fixed top on scroll
   */
  let selectHeader = select('#header')
  if (selectHeader) {
    let headerOffset = selectHeader.offsetTop
    let nextElement = selectHeader.nextElementSibling
    const headerFixed = () => {
      if ((headerOffset - window.scrollY) <= 0) {
        selectHeader.classList.add('fixed-top')
        nextElement.classList.add('scrolled-offset')
      } else {
        selectHeader.classList.remove('fixed-top')
        nextElement.classList.remove('scrolled-offset')
      }
    }
    window.addEventListener('load', headerFixed)
    onscroll(document, headerFixed)

    const headerScrolled = () => {
      if (window.scrollY > 100) {
        selectHeader.classList.add('header-scrolled')
      } else {
        selectHeader.classList.remove('header-scrolled')
      }
    }
    window.addEventListener('load', headerScrolled)
    onscroll(document, headerScrolled)


  }

  /**
   * Back to top button
   */
  let backtotop = select('.back-to-top')
  if (backtotop) {
    console.log('sanaaa')
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add('active')
      } else {
        backtotop.classList.remove('active')
      }
    }
    window.addEventListener('load', toggleBacktotop)
    onscroll(document, toggleBacktotop)
  }

  /**
   * Mobile nav toggle
   */
  on('click', '.mobile-nav-toggle', function(e) {
    select('#navbar').classList.toggle('navbar-mobile')
    this.classList.toggle('bi-list')
    this.classList.toggle('bi-x')
  })

  /**
   * Mobile nav dropdowns activate
   */
  on('click', '.navbar .dropdown > a', function(e) {
    if (select('#navbar').classList.contains('navbar-mobile')) {
      e.preventDefault()
      this.nextElementSibling.classList.toggle('dropdown-active')
    }
  }, true)

  /**
   * Scrool with ofset on links with a class name .scrollto
   */
  on('click', '.scrollto', function(e) {
    if (select(this.hash)) {
      e.preventDefault()

      let navbar = select('#navbar')
      if (navbar.classList.contains('navbar-mobile')) {
        navbar.classList.remove('navbar-mobile')
        let navbarToggle = select('.mobile-nav-toggle')
        navbarToggle.classList.toggle('bi-list')
        navbarToggle.classList.toggle('bi-x')
      }
      scrollto(this.hash)
    }
  }, true)

  /**
   * Scroll with ofset on page load with hash links in the url
   */
  window.addEventListener('load', () => {
    if (window.location.hash) {
      if (select(window.location.hash)) {
        scrollto(window.location.hash)
      }
    }
  });

  /**
   * Initiate glightbox 
   */
  const glightbox = GLightbox({
    selector: '.glightbox'
  });

 

    window.addEventListener('load', () => {
      let portfolioContainer = select('.portfolio-container');
      if (portfolioContainer) {

        // isotope.current = new Isotope(portfolioContainer, {
        //   itemSelector: '.portfolio-item',
        //   layoutMode: 'fitRows',
        // })

        // filterKey === '*'
        // ? isotope.current.arrange({filter: `*`})
        // : isotope.current.arrange({filter: `.${filterKey}`})
   


        // isotope.current = new Isotope(portfolioContainer, {
        //   itemSelector: '.portfolio-item',
        //   layoutMode: 'fitRows'
        // });
  
        let portfolioFilters = select('#portfolio-flters li', true);
  
        // on('click', '#portfolio-flters li', function(e) {
        //   e.preventDefault();
        //   portfolioFilters.forEach(function(el) {
        //     el.classList.remove('filter-active');
        //   });
        //   this.classList.add('filter-active');
  
        //   isotope.current.arrange({
        //     filter: this.getAttribute('data-filter')
        //   });
        //   isotope.current.on('arrangeComplete', function() {
        //     AOS.refresh()
        //   });
        // }, true);


      }
  
    });
    
    window.addEventListener('load', () => {
      AOS.init({
        duration: 1000,
        easing: 'ease-in-out',
        once: true,
        mirror: false
      })
    });

  //   isotope.current = new Isotope('.portfolio-container', {
  //     itemSelector: '.portfolio-item',
  //     layoutMode: 'fitRows',
  //   })
  //   // cleanup
  //   return () => isotope.current.destroy()
  

 
    
  },[]);

    return (

        <div>

<div>
 
  {/* ======= Header ======= */}

  <header id="header" className="fixed-top header-transparent">
    <div className="container d-flex align-items-center justify-content-between">
      <h1 className="logo">
        <a href="index.html">Wet Wipes</a>
        {/* <img src={`../templates/Flexor/assets/img/real/wet wipes new logo-01.png`}></img> */}
        
        </h1>
      {/* Uncomment below if you prefer to use an image logo */}
      {/* <a href="index.html" class="logo"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>*/}
      <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
          <li><a className="nav-link scrollto" href="#about">About</a></li>
          <li><a className="nav-link scrollto" href="#services">Services</a></li>
          <li><a className="nav-link scrollto " href="#portfolio">Catalogue</a></li>
          <li><a className="nav-link scrollto" href="#team">Team</a></li>
          <li><a className="nav-link scrollto" href="#pricing">Pricing</a></li>
          <li className="dropdown"><a href="#"><span>Drop Down</span> <i className="bi bi-chevron-down" /></a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li>
          <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle" />
      </nav>{/* .navbar */}
    </div>
  </header>

  {/* <header id="header" className="d-flex align-items-center">
    <div className="container d-flex align-items-center justify-content-between">
      <div className="logo">
        <h1><a href="index.html">Wet Wipes</a></h1>
       
      </div>
      <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
          <li><a className="nav-link scrollto" href="#about">About</a></li>
          <li><a className="nav-link scrollto" href="#services">Services</a></li>
          <li><a className="nav-link scrollto " href="#portfolio">Portfolio</a></li>
          <li><a className="nav-link scrollto" href="#team">Team</a></li>
          <li><a className="nav-link scrollto" href="#pricing">Pricing</a></li>
          <li><a href="blog.html">Blog</a></li>
          <li className="dropdown"><a href="#"><span>Drop Down</span> <i className="bi bi-chevron-down" /></a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li>
          <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle" />
      </nav>
  
    </div>
  </header> */}


  
  {/* End Header */}

  {/* ======= Hero Section ======= */}

   


  <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
    <div className="container" data-aos="fade-in">
      <h1>Wet Wipes Indonesia</h1>
     
      {/* <h2>We are team of talented designers making websites with Bootstrap</h2> */}
      <div className="d-flex align-items-center">
        <i className="bx bxs-right-arrow-alt get-started-icon" />
        <a href="#about" className="btn-get-started scrollto">Get Started</a>
      </div>
    </div>
  </section>
  
  
  
  {/* End Hero */}

  
  <main id="main">
    {/* ======= Why Us Section ======= */}
    <section id="why-us" className="why-us">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-5" data-aos="fade-up">
            <div className="content">
              <h3>Mengapa Wet Wipes?</h3>
              <p>
              Kami selalu berdedikasi untuk menjamin kepuasan customer dengan memberikan layanan yang cepat dan produk berkualitas.
              </p>
              <div className="text-center">
                <a href="#" className="more-btn">Learn More <i className="bx bx-chevron-right" /></a>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-7 d-flex">
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <div className="row">

                <div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-receipt" />
                    <h4>Anti Iritasi</h4>
                    <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
                  </div>
                </div>
                
                <div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={200}>
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-cube-alt" />
                    <h4>Formulasi Food Grade</h4>
                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                  </div>
                </div>

                <div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={300}>
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-images" />
                    <h4>Membasmi 99,9% bakteri</h4>
                    <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                  </div>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>{/* End Why Us Section */}
    {/* ======= About Section ======= */}
    <section id="about" className="about section-bg">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative" data-aos="fade-right">
            <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox mb-4" />
          </div>
          <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
            <h4 data-aos="fade-up">Tentang Kami</h4>
           
<p data-aos="fade-up">PT. Wet Wipes Indonesia merupakan produsen yang telah
dipercaya oleh berbagai perusahaan dan ratusan customer
sebagai penyedia tissue basah dengan kemasan satuan</p>

<p data-aos="fade-up">Produk kami merupakan solusi yang tepat sebagai
perlindungan kesehatan karena menggunakan formulasi
ANTIBACTERIAL yang telah teruji secara klinis untuk
membunuh virus, kuman, dan bakteri.</p>
            
            <h3 data-aos="fade-up">Keunggulan tissue basah kami:</h3>
            
            <div className="icon-box" data-aos="fade-up">
              <div className="icon"><i className="bx bx-fingerprint" /></div>
              <h4 className="title"><a href>Sebagai hand sanitizer</a></h4>
              <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
            </div>
            <div className="icon-box" data-aos="fade-up" data-aos-delay={100}>
              <div className="icon"><i className="bx bx-gift" /></div>
              <h4 className="title"><a href>Sebagai sterilisasi pada barang</a></h4>
              <p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
            </div>
            <div className="icon-box" data-aos="fade-up" data-aos-delay={200}>
              <div className="icon"><i className="bx bx-atom" /></div>
              <h4 className="title"><a href>Mengandung pelembab kulit</a></h4>
              <p className="description">Explicabo est voluptatum asperiores consequatur magnam. Et veritatis odit. Sunt aut deserunt minus aut eligendi omnis</p>
            </div>
          </div>
        </div>
      </div>
    </section>{/* End About Section */}
    {/* ======= Clients Section ======= */}
    <section id="clients" className="clients">
      <div className="container" data-aos="fade-up">

        <Swiper

        speed={400}
        loop={true}
        autoplay = {{
          delay: 5000,
          disableOnInteraction: false,
        }}
        slidesPerView={'auto'}
        pagination={{
          type:'bullets',
          clickable: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 40
          },
          480: {
            slidesPerView: 3,
            spaceBetween: 60
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 80
          },
          992: {
            slidesPerView: 6,
            spaceBetween: 120
          }
        }}

        >
        <div className="clients-slider swiper-container">
          <div className="swiper-wrapper align-items-center">
            {fClients()}
            {/* <div className="swiper-slide"><img src="assets/img/clients/client-1.png" className="img-fluid" alt /></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-2.png" className="img-fluid" alt /></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-3.png" className="img-fluid" alt /></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-4.png" className="img-fluid" alt /></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-5.png" className="img-fluid" alt /></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-6.png" className="img-fluid" alt /></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-7.png" className="img-fluid" alt /></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-8.png" className="img-fluid" alt /></div> */}
          </div>
          <div className="swiper-pagination" />
        </div>
        
        </Swiper>

      </div>
    </section>{/* End Clients Section */}

    {/* ======= Services Section ======= */}
    <section id="services" className="services section-bg">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Services</h2>
          <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6" data-aos="fade-up">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-briefcase" /></div>
              <h4 className="title"><a href>Lorem Ipsum</a></h4>
              <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={100}>
            <div className="icon-box">
              <div className="icon"><i className="bi bi-card-checklist" /></div>
              <h4 className="title"><a href>Dolor Sitema</a></h4>
              <p className="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={200}>
            <div className="icon-box">
              <div className="icon"><i className="bi bi-bar-chart" /></div>
              <h4 className="title"><a href>Sed ut perspiciatis</a></h4>
              <p className="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={300}>
            <div className="icon-box">
              <div className="icon"><i className="bi bi-binoculars" /></div>
              <h4 className="title"><a href>Magni Dolores</a></h4>
              <p className="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={400}>
            <div className="icon-box">
              <div className="icon"><i className="bi bi-brightness-high" /></div>
              <h4 className="title"><a href>Nemo Enim</a></h4>
              <p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={500}>
            <div className="icon-box">
              <div className="icon"><i className="bi bi-calendar4-week" /></div>
              <h4 className="title"><a href>Eiusmod Tempor</a></h4>
              <p className="description">Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
            </div>
          </div>
        </div>
      </div>
    </section>{/* End Services Section */}

    {/* ======= Values Section ======= */}
    <section id="values" className="values">
      <div className="container">
        <div className="row">


          {fValues()}

          {/* <div className="col-md-6 d-flex align-items-stretch" data-aos="fade-up"  data-aos-delay={0}>
            <div className="card" style={{backgroundImage: 'url(../templates/Flexor/assets/img/values-1.jpg)'}}>
              <div className="card-body">
                <h5 className="card-title"><a href>Our Mission</a></h5>
                <p className="card-text">Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>
                <div className="read-more"><a href="#"><i className="bi bi-arrow-right" /> Read More</a></div>
              </div>
            </div>
          </div> */}


          {/* <div className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay={100}>
            <div className="card" style={{backgroundImage: 'url(assets/img/values-2.jpg)'}}>
              <div className="card-body">
                <h5 className="card-title"><a href>Our Plan</a></h5>
                <p className="card-text">Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem.</p>
                <div className="read-more"><a href="#"><i className="bi bi-arrow-right" /> Read More</a></div>
              </div>
            </div>
          </div> */}


          {/* <div className="col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay={200}>
            <div className="card" style={{backgroundImage: 'url(assets/img/values-3.jpg)'}}>
              <div className="card-body">
                <h5 className="card-title"><a href>Our Vision</a></h5>
                <p className="card-text">Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores.</p>
                <div className="read-more"><a href="#"><i className="bi bi-arrow-right" /> Read More</a></div>
              </div>
            </div>
          </div> */}

          {/* <div className="col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay={300}>
            <div className="card" style={{backgroundImage: 'url(assets/img/values-4.jpg)'}}>
              <div className="card-body">
                <h5 className="card-title"><a href>Our Care</a></h5>
                <p className="card-text">Nostrum eum sed et autem dolorum perspiciatis. Magni porro quisquam laudantium voluptatem.</p>
                <div className="read-more"><a href="#"><i className="bi bi-arrow-right" /> Read More</a></div>
              </div>
            </div>
          </div> */}

        </div>
      </div>
    </section>{/* End Values Section */}

    {/* ======= Testimonials Section ======= */}
    <section id="testimonials" className="testimonials">
      <div className="container position-relative" data-aos="fade-up">
          
      <Swiper
      speed={600}
      loop={true}
      autoplay={{
        delay:5000,
        disableOnInteraction: false,
      }}
      slidesPerView={'auto'}
      pagination={{
        
        type:'bullets',
        clickable: true,
      }}>  
        <div className="testimonials-slider swiper-container" data-aos="fade-up" data-aos-delay={100}>
         
          <div className="swiper-wrapper">
          

              <div>

                {fTestimonials()}

              </div>            
            
          </div>
          <div className="swiper-pagination" />
          
        </div>

        </Swiper>
     
      </div>
    </section>{/* End Testimonials Section */}

    {/* ======= Portfolio Section ======= */}
    <section id="portfolio" className="portfolio">
      <div className="container">

        <div className="section-title">
          <h2 data-aos="fade-up">Catalogueee</h2>
          <p data-aos="fade-up">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay={100}>


          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li accessKey="All" onClick={handleOnClick}>All</li>
              <li >Web</li>
              <li >Card</li>
              <li >App</li>
            </ul>
          </div>

        </div>

        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay={200}>
          {fPortfolio()}
          
        </div>


      </div>


    </section>
    
    
    
    
    {/* End Portfolio Section */}

    {/* ======= Team Section ======= */}

    {/* <section id="team" className="team section-bg">
      <div className="container">
        <div className="section-title">
          <h2 data-aos="fade-up">Team</h2>
          <p data-aos="fade-up">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up">
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team-1.jpg" className="img-fluid" alt />
                <div className="social">
                  <a href><i className="bi bi-twitter" /></a>
                  <a href><i className="bi bi-facebook" /></a>
                  <a href><i className="bi bi-instagram" /></a>
                  <a href><i className="bi bi-linkedin" /></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Walter White</h4>
                <span>Chief Executive Officer</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team-2.jpg" className="img-fluid" alt />
                <div className="social">
                  <a href><i className="bi bi-twitter" /></a>
                  <a href><i className="bi bi-facebook" /></a>
                  <a href><i className="bi bi-instagram" /></a>
                  <a href><i className="bi bi-linkedin" /></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Sarah Jhonson</h4>
                <span>Product Manager</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={200}>
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team-3.jpg" className="img-fluid" alt />
                <div className="social">
                  <a href><i className="bi bi-twitter" /></a>
                  <a href><i className="bi bi-facebook" /></a>
                  <a href><i className="bi bi-instagram" /></a>
                  <a href><i className="bi bi-linkedin" /></a>
                </div>
              </div>
              <div className="member-info">
                <h4>William Anderson</h4>
                <span>CTO</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={300}>
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team-4.jpg" className="img-fluid" alt />
                <div className="social">
                  <a href><i className="bi bi-twitter" /></a>
                  <a href><i className="bi bi-facebook" /></a>
                  <a href><i className="bi bi-instagram" /></a>
                  <a href><i className="bi bi-linkedin" /></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Amanda Jepson</h4>
                <span>Accountant</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    
    
    
    {/* End Team Section */}
    {/* ======= Pricing Section ======= */}
    <section id="pricing" className="pricing">
      <div className="container">
        <div className="section-title">
          <h2 data-aos="fade-up">Pricing</h2>
          <p data-aos="fade-up">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6" data-aos="fade-up">
            <div className="box">
              <h3>Free</h3>
              <h4><sup>$</sup>0<span> / month</span></h4>
              <ul>
                <li>Aida dere</li>
                <li>Nec feugiat nisl</li>
                <li>Nulla at volutpat dola</li>
                <li className="na">Pharetra massa</li>
                <li className="na">Massa ultricies mi</li>
              </ul>
              <div className="btn-wrap">
                <a href="#" className="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay={100}>
            <div className="box featured">
              <h3>Business</h3>
              <h4><sup>$</sup>19<span> / month</span></h4>
              <ul>
                <li>Aida dere</li>
                <li>Nec feugiat nisl</li>
                <li>Nulla at volutpat dola</li>
                <li>Pharetra massa</li>
                <li className="na">Massa ultricies mi</li>
              </ul>
              <div className="btn-wrap">
                <a href="#" className="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay={200}>
            <div className="box">
              <h3>Developer</h3>
              <h4><sup>$</sup>29<span> / month</span></h4>
              <ul>
                <li>Aida dere</li>
                <li>Nec feugiat nisl</li>
                <li>Nulla at volutpat dola</li>
                <li>Pharetra massa</li>
                <li>Massa ultricies mi</li>
              </ul>
              <div className="btn-wrap">
                <a href="#" className="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay={300}>
            <div className="box">
              <span className="advanced">Advanced</span>
              <h3>Ultimate</h3>
              <h4><sup>$</sup>49<span> / month</span></h4>
              <ul>
                <li>Aida dere</li>
                <li>Nec feugiat nisl</li>
                <li>Nulla at volutpat dola</li>
                <li>Pharetra massa</li>
                <li>Massa ultricies mi</li>
              </ul>
              <div className="btn-wrap">
                <a href="#" className="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>{/* End Pricing Section */}

    {/* ======= F.A.Q Section ======= */}
    <section id="faq" className="faq section-bg">
      <div className="container">
        <div className="section-title">
          <h2 data-aos="fade-up">F.A.Q</h2>
          <p data-aos="fade-up">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>
        <div className="faq-list">
          <ul>


            <li data-aos="fade-up" data-aos-delay={100}>
              <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">Berapa Lama Tissue Dapat Disimpan? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
              <div id="faq-list-2" className="collapse show" data-bs-parent=".faq-list">
                <p>
                Kami menggunakan bahan material yang kedap air dan udara, sehingga tissue
dapat disimpan sampai dengan 1 tahun, tidak cepat kering.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay={200}>
              <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">Dolor sit amet consectetur adipiscing elit? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
              <div id="faq-list-3" className="collapse show" data-bs-parent=".faq-list">
                <p>
                  Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
                </p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay={300}>
              <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">Tempus quam pellentesque nec nam aliquam sem et tortor consequat? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
              <div id="faq-list-4" className="collapse show" data-bs-parent=".faq-list">
                <p>
                  Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in.
                </p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay={400}>
              <i className="bx bx-help-circle icon-help" /> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">Tortor vitae purus faucibus ornare. Varius vel pharetra vel turpis nunc eget lorem dolor? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
              <div id="faq-list-5" className="collapse show" data-bs-parent=".faq-list">
                <p>
                  Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed. Ut venenatis tellus in metus vulputate eu scelerisque.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>{/* End F.A.Q Section */}

    {/* ======= Contact Section ======= */}
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title">
          <h2 data-aos="fade-up">Contact</h2>
          <p data-aos="fade-up">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up">
            <div className="info-box">
              <i className="bx bx-map" />
              <h3>Our Address</h3>
              <p>Scientia Business Park. Tower 2, 2nd Floor Block 0/2
Jl. Boulevard Gading Serpong, Tangerang 15810, Indonesia</p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay={100}>
            <div className="info-box">
              <i className="bx bx-envelope" />
              <h3>Email Us</h3>
              <p>wetwipesindonesiaa@gmail.com<br />contact@example.com</p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay={200}>
            <div className="info-box">
              <i className="bx bx-phone-call" />
              <h3>Call Us</h3>
              <p>+62 21 2188 5370<br />+62 812 1388 18681</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center" data-aos="fade-up" data-aos-delay={300}>
          <div className="col-xl-9 col-lg-12 mt-4">
            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows={5} placeholder="Message" required defaultValue={""} />
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message" />
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>
        </div>
      </div>
    </section>{/* End Contact Section */}

  </main>{/* End #main */}

  {/* ======= Footer ======= */}
  <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 footer-contact">
            <h3>Wet Wipes</h3>
            <p>
              {/* A108 Adam Street <br />
              New York, NY 535022<br />
              United States <br /><br /> */}
              Scientia Business Park. Tower 2, 2nd Floor Block 0/2 Jl. Boulevard Gading Serpong, Tangerang 15810, Indonesia
              <br /><br />
              <strong>Phone:</strong> +62 812 1388 18681<br />
              <strong>Email:</strong> wetwipesindonesiaa@gmail.com<br />
            </p>
          </div>
          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right" /> <a href="#">Home</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#">About us</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#">Services</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#">Terms of service</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#">Privacy policy</a></li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i className="bx bx-chevron-right" /> <a href="#">Web Design</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#">Web Development</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#">Product Management</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#">Marketing</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#">Graphic Design</a></li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Join Our Newsletter</h4>
            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
            <form action method="post">
              <input type="email" name="email" /><input type="submit" defaultValue="Subscribe" />
            </form>
          </div>
        </div>
      </div>
    </div>
    <div className="container d-lg-flex py-4">
      <div className="me-lg-auto text-center text-lg-start">
        <div className="copyright">
          © Copyright <strong><span>Wet Wipes</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
          {/* All the links in the footer should remain intact. */}
          {/* You can delete the links only if you purchased the pro version. */}
          {/* Licensing information: https://bootstrapmade.com/license/ */}
          {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/Wet Wipes-free-multipurpose-bootstrap-template/ */}
          Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
        </div>
      </div>
      <div className="social-links text-center text-lg-right pt-3 pt-lg-0">
        <a href="#" className="twitter"><i className="bx bxl-twitter" /></a>
        <a href="#" className="facebook"><i className="bx bxl-facebook" /></a>
        <a href="#" className="instagram"><i className="bx bxl-instagram" /></a>
        <a href="#" className="google-plus"><i className="bx bxl-skype" /></a>
        <a href="#" className="linkedin"><i className="bx bxl-linkedin" /></a>
        <a href="#" className="whatsapp"><i className="bx bxl-whatsapp" /></a>
      </div>
    </div>
  </footer>{/* End Footer */}
  <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bx bxl-whatsapp" /></a>
</div>



        </div>
    )
}